import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styles from '../components/styles/contact.module.css';
import { HeaderImage } from '../components/HeaderImage.jsx';
import { ContactForm } from '../components/ContactForm';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulVjcHpRecruitPositionsAvailable(
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            title
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title='お問い合わせ' />
      <HeaderImage
        title='CONTACT'
        subTitle='お問い合わせ'
        fileName='contact.jpg'
      />
      <div className={styles.comment}>
        <p>サービスのご依頼、お問合せ、その他ご相談を承ります。</p>
        {!data.allContentfulVjcHpRecruitPositionsAvailable.edges.some(
          (edge) => edge.node.title === '募集なし'
        ) && (
          <p>
            採用応募をご希望の場合は
            <Link to='/application/' className={styles.applicationLink}>
              こちら
            </Link>
            からご応募ください。
          </p>
        )}
        <br />
        <p>*は必須項目です。</p>
      </div>
      <div className={styles.contactContent}>
        <section className={styles.contactFormBox}>
          <section className={styles.boxContent}>
            <ContactForm />
          </section>
        </section>
      </div>
    </Layout>
  );
};
