import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ShowModalButton } from './ShowModalButton';
import styles from './styles/form.module.css';

const currencies = [
  {
    value: '',
    label: '',
  },
  {
    value: 'sales',
    label: 'サービス・その他に関するお問い合わせ',
  },
  {
    value: 'humanResources',
    label: '採用に関するお問い合わせ',
  },
];

const initialValueItems = {
  contactInfomation: '',
  companyName: '',
  divisionName: '',
  contactPersonName: '',
  contactPersonNameRuby: '',
  cooporateName: '',
  name: '',
  tel: '',
  email: '',
  url: '',
  content: '',
  check: false,
};

const privacyPolicyForSales =
(<p>当社では事業活動及びアフターフォローのため氏名・住所・電話番号等の個人情報を識別できる情報（以下、個人情報といいます）をセールスDiv.がお預かり致します。<br/>
個人情報の提出に関しては原則任意といたします。ただし、提出をされない場合にはお問い合わせに関する対応に支障をきたす場合があります事をご了承下さい。なお、提出された個人情報に不足がある場合には、再提出を依頼する場合があります。
<br/><br/>
お預かりいたしました個人情報につきましては問い合わせしたご本人様へのご連絡のために利用し、法令に基づく場合の利用要請等を除き、第三者へ委託や提供することはありません。<br/><br/>
なお、当社がお預かりしている個人情報に関して、ご本人より利用目的の通知、開示、訂正、追加、削除、利用の停止、消去及び第三者への提供の停止（以下「開示等」という）の求めがあった場合には、遅滞なく対応します。<br/><br />
また、開示等の請求手続きは下記の＜個人情報苦情及びご相談窓口＞までお問合せ下さい。<br/>
開示等の内容を確認し、個人情報保護管理責任者より、弊社所定の申請書をお送り致します。<br/>
必要に応じ、申請者本人であることを確認できる公的証明書（免許証・住民票等）の写しを申請書と併せて提出して頂く場合があります。<br/>
代理人を通じての開示請求に際しては、本人の代理人に当たる者であることを説明する書類（委任状等）が必要となります。<br/>
当社は、次のいずれかに該当する場合には、当該求めに関わる個人情報の全部又は一部について開示をお断りさせていただく場合があります。<br/>
・本人又は第三者の生命、身体、財産その他権利利益を害するおそれがある場合<br/>
・当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合<br/>
・法令に違反することとなる場合<br/><br/>
＜個人情報苦情及びご相談窓口＞<br/>
バークリー・ジャパン株式会社<br/>
代表取締役 井上 将之<br/>
東京都新宿区新宿5-2-3 MRCビル7F<br/>
03-5363-2090<br/>
</p>);

const privacyPolicyForHumanResources = (
<p>当社では採用業務管理のため氏名・住所・電話番号等の個人情報を識別できる情報（以下、個人情報といいます）を人事総務部がお預かり致します。<br/>
個人情報の提出に関しては原則任意といたします。ただし、提出をされない場合にはお問い合わせに関する対応に支障をきたす場合があります事をご了承下さい。なお、提出された個人情報に不足がある場合には、再提出を依頼する場合があります。<br/><br/>
お預かりいたしました個人情報につきましては問い合わせしたご本人様へのご連絡のために利用し、法令に基づく場合の利用要請等を除き、第三者へ委託や提供することはありません。<br/><br/>
なお、当社がお預かりしている個人情報に関して、ご本人より利用目的の通知、開示、訂正、追加、削除、利用の停止、消去及び第三者への提供の停止（以下「開示等」という）の求めがあった場合には、遅滞なく対応します。<br/><br/>
また、開示等の請求手続きは下記の＜個人情報苦情及びご相談窓口＞までお問合せ下さい。<br/>
開示等の内容を確認し、個人情報保護管理責任者より、弊社所定の申請書をお送り致します。<br/>
必要に応じ、申請者本人であることを確認できる公的証明書（免許証・住民票等）の写しを申請書と併せて提出して頂く場合があります。<br/>
代理人を通じての開示請求に際しては、本人の代理人に当たる者であることを説明する書類（委任状等）が必要となります。<br/>
当社は、次のいずれかに該当する場合には、当該求めに関わる個人情報の全部又は一部について開示をお断りさせていただく場合があります。<br/>
・本人又は第三者の生命、身体、財産その他権利利益を害するおそれがある場合<br/>
・当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合<br/>
・法令に違反することとなる場合<br/><br/>

＜個人情報苦情及びご相談窓口＞<br/>
バークリー・ジャパン株式会社<br/>
代表取締役 井上 将之<br/>
東京都新宿区新宿5-2-3 MRCビル7F<br/>
03-5363-2090<br/>
</p>
)


export const ContactForm = () => {
  const [currency, setCurrency] = useState('');
  const [isSales, setIsSales] = useState(false);
  const [isHR, setIsHR] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [submittingState, setSubmittingState] = useState(false);
  const [sentState, setSentState] = useState(false);
  const [sentMessage, setSentMessage] = useState({
    messageTitle: '',
    message: <p></p>,
  });

  const formItems = [
    {
      type: 'text',
      name: 'companyName',
      label: '企業名*',
      state: isSales,
    },
    {
      type: 'text',
      name: 'cooporateName',
      label: '企業名',
      state: isHR,
    },
    {
      type: 'text',
      name: 'divisionName',
      label: '部署名*',
      state: isSales,
    },
    {
      type: 'text',
      name: 'contactPersonName',
      label: 'ご担当者名*',
      state: isSales,
    },
    {
      type: 'text',
      name: 'contactPersonNameRuby',
      label: 'ご担当者名(ふりがな)*',
      state: isSales,
    },
    {
      type: 'text',
      name: 'name',
      label: '氏名*',
      state: isHR,
    },
    {
      type: 'tel',
      name: 'tel',
      label: '電話番号*',
      state: currency,
    },
    {
      type: 'email',
      name: 'email',
      label: 'メールアドレス*',
      state: currency,
    },
    {
      type: 'url',
      name: 'url',
      label: 'URL',
      state: isSales,
    },
    {
      type: 'text',
      name: 'content',
      label: '内容*',
      state: currency,
      multiline: true,
      rows: 10,
    },
  ];

  const validation = (currency) =>
    Yup.lazy(() => {
      const PHONE_REGULAR_EXPRESSION =
        '^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$';

      if (currency === 'sales') {
        return Yup.object().shape({
          companyName: Yup.string()
            .max(200, '200文字以下で入力してください')
            .required('必須項目です'),
          divisionName: Yup.string()
            .max(100, '100文字以下で入力してください')
            .required('必須項目です'),
          contactPersonName: Yup.string()
            .max(100, '100文字以下で入力してください')
            .required('必須項目です'),
          contactPersonNameRuby: Yup.string()
            .max(100, '100文字以下で入力してください')
            .required('必須項目です'),
          tel: Yup.string()
            .matches(
              PHONE_REGULAR_EXPRESSION,
              '電話番号の形式で入力してください'
            )
            .max(15, '電話番号の形式で入力してください')
            .required('必須項目です'),
          email: Yup.string()
            .email('メールアドレスの形式で入力してください')
            .max(256, 'メールアドレスの形式で入力してください')
            .required('必須項目です'),
          url: Yup.string()
            .url('URLの形式で入力してください')
            .max(2083, 'URLの形式で入力してください'),
          content: Yup.string()
            .max(4000, '4000文字以下で入力してください')
            .required('必須項目です'),
          check: Yup.boolean().oneOf([true], '同意にチェックして下さい。'),
        });
      } else if (currency === 'humanResources') {
        return Yup.object().shape({
          cooporateName: Yup.string().max(200, '200文字以下で入力してください'),
          name: Yup.string()
            .max(100, '100文字以下で入力してください')
            .required('必須項目です'),
          tel: Yup.string()
            .matches(PHONE_REGULAR_EXPRESSION, '電話番号を入力してください')
            .max(15, '電話番号の形式で入力してください')
            .required('必須項目です'),
          email: Yup.string()
            .email('メールアドレスの形式で入力してください')
            .max(256, 'メールアドレスの形式で入力してください')
            .required('必須項目です'),
          content: Yup.string()
            .max(4000, '4000文字以下で入力してください')
            .required('必須項目です'),
          check: Yup.boolean().oneOf([true], '同意にチェックして下さい。'),
        });
      }
      return Yup.object().shape({});
    });

  const handleChangeCurrency = (event) => {
    if (event.target.value === 'sales') {
      setIsHR(false);
      setIsSales(true);
    } else if (event.target.value === 'humanResources') {
      setIsHR(true);
      setIsSales(false);
    } else {
      setIsHR(false);
      setIsSales(false);
    }
    setCurrency(event.target.value);
  };

  useEffect(() => {
    axios({
      method: 'post',
      url: '/mail/generateToken.php',
      withCredentials: true,
    });
  }, []);

  const submitHandler = (values) => {
    setSubmittingState(true);
    axios({
      method: 'post',
      url: '/mail/',
      headers: {
        'content-type': 'multipart/form-data',
      },
      withCredentials: true,
      data: values,
    })
      .then((response) => {
        setSentState(true);
        setSentMessage({
          messageTitle: response.data.messageTitle,
          message: response.data.sent ? (
            <>
              <p>お問い合わせありがとうございます。</p>
              <p>ご入力頂いたメールアドレスに自動返信いたしました。</p>
              <br />
              <p>メールの内容をご確認ください。</p>
            </>
          ) : (
            <p>{response.data.message}</p>
          ),
        });
        setSubmittingState(false);
      })
      .catch(() => {
        setSentState(true);
        setSentMessage({
          messageTitle: '送信エラー',
          message: <p>恐れ入りますが、もう一度お試し下さい。</p>,
        });
        setSubmittingState(false);
      });
  };

  const closeModalAction = () => {
    setModalState(false);
    setSentState(false);
  };

  const submitEventHandler = () => {
    document.getElementById('submitButton').click();
  };

  return (
    <>
      <div className={styles.contactFormWrap}>
        <Formik
          initialValues={initialValueItems}
          validationSchema={validation(currency)}
        >
          {(props) => (
            <Form id='contactForm' noValidate>
              <TextField
                name='contactInfomation'
                select
                label='問い合わせ内容を選択ください*'
                value={(props.values.contactInfomation = currency)}
                onChange={handleChangeCurrency}
                SelectProps={{
                  native: true,
                }}
                variant='outlined'
              >
                {currencies.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <div className={!currency ? styles.hidden : ''}>
                {formItems.map((formItem, i) => (
                  <div
                    className={`${!formItem.state ? styles.hidden : ''} ${
                      styles.form
                    }`}
                    key={`contact-form-${i}`}
                  >
                    <TextField
                      type={formItem.type}
                      name={formItem.name}
                      label={formItem.label}
                      multiline={formItem.multiline}
                      rows={formItem.rows}
                      value={props.values[formItem.name]}
                      onChange={props.handleChange(formItem.name)}
                      onBlur={props.handleBlur(formItem.name)}
                      helperText={
                        props.touched[formItem.name]
                          ? props.errors[formItem.name]
                          : ''
                      }
                      variant='outlined'
                    />
                  </div>
                ))}
                <div className={styles.privacyPolicyWrap}>
                  <div className={styles.privacyPolicyLabel}>
                    プライバシーポリシー
                  </div>
                  <p className={styles.privacyPolicyMessage}>
                    {isSales ? privacyPolicyForSales: privacyPolicyForHumanResources}
                  </p>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='check'
                        checked={props.values.check}
                        onChange={props.handleChange('check')}
                        color='primary'
                      />
                    }
                    label={
                      <span className={styles.agree}>
                        プライバシーポリシーに同意しました。
                      </span>
                    }
                  />
                  {props.touched.check && (
                    <p className={styles.checkErrorMessage}>
                      {props.errors.check}
                    </p>
                  )}
                </div>
                <div>
                  <ShowModalButton
                    buttonTitle='送信'
                    buttonClassName={styles.showModalButton}
                    buttonFunction={
                      props.dirty && props.isValid
                        ? () => setModalState(true)
                        : props.handleSubmit
                    }
                  />
                </div>
                <button
                  type='submit'
                  id='submitButton'
                  onClick={() => submitHandler(props.values)}
                  aria-label='submit'
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className={`${styles.modalWrap} ${modalState && styles.showModal}`}>
        <div
          role='presentation'
          className={styles.mask}
          onClick={() => !submittingState && closeModalAction()}
        ></div>
        <div className={styles.modal}>
          <div
            role='presentation'
            className={styles.closeButton}
            onClick={() => !submittingState && closeModalAction()}
          >
            ×
          </div>
          <div id='modal' className={styles.modalMessageWrap}>
            <p className={styles.modalMessage}>送信してよろしいですか？</p>
          </div>
          <div className={styles.modalButtonWrap}>
            <button
              type='button'
              onClick={submitEventHandler}
              className={styles.submitButton}
            >
              <p className={styles.buttonMessage}>OK</p>
            </button>
          </div>
          <div
            className={`${styles.submitAfterModal} ${
              submittingState || sentState
                ? styles.submitting
                : styles.transparent
            }`}
          >
            <div
              className={
                !sentState ? styles.transparent : styles.sentMessageTitleArea
              }
            >
              <h2
                className={!sentState ? styles.hidden : styles.sentMessageTitle}
              >
                {sentMessage.messageTitle}
              </h2>
            </div>
            <div
              className={!sentState ? styles.hidden : styles.sentMessageArea}
            >
              <div className={!sentState ? styles.hidden : styles.sentMessage}>
                {sentMessage.message}
              </div>
            </div>
            <div className={sentState ? styles.transparent : styles.loaderWrap}>
              <div className={sentState ? styles.transparent : styles.loader}>
                Loading...
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
